'use client';

import { ChangeEvent, useState, useEffect } from 'react';
import clsx from 'clsx';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import { Money } from 'shared/types/product';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import FinanceAmountInput from './finance-amount-input';
import FinanceDeposit from './finance-deposit';
import FinanceSummary from './finance-summary';
import FinanceTermOptions, { FinanceTermOption } from './finance-term-options';
import { calculate, calculateDeposit, FinanceCalculation } from './util/calculate';

interface FinanceCalculatorProp {
  amount: number;
  currencyCode: string;
  configurableAmount?: boolean;
  minimumOrderAmount?: boolean;
  financeProducts: FinanceProduct[];
  termCallback?: (financeTerm: FinanceTermOption) => void;
  depositCallback?: (deposit: string) => void;
}

interface FinanceDepositPrecalculation {
  depositPercentage: number;
  financeProduct: FinanceProduct;
  financeCalculation: FinanceCalculation;
}

interface FinanceDepositOptions {
  [k: string]: FinanceDepositPrecalculation[];
}

export const MIN_FINANCE_AMOUNT = parseInt(process.env.NEXT_PUBLIC_MIN_FINANCE_AMOUNT ?? '0');

export default function FinanceCalculator({
  amount,
  currencyCode = 'GBP',
  configurableAmount = true,
  financeProducts,
  termCallback,
  depositCallback,
}: FinanceCalculatorProp): JSX.Element {
  const amountInMoney: Money = {
    centAmount: amount,
    currencyCode: currencyCode,
    fractionDigits: 2,
  };

  const amountInCurrency = CurrencyHelpers.formatForCurrency(amountInMoney);
  const amountToString = amountInCurrency.slice(1).replace(',', '');
  const currencySymbol = amountInCurrency.charAt(0);

  const financeOptions = financeProducts.map((f) => ({
    productId: f.productId,
    term: f.months,
    interest: f.apr,
    label: f.label ?? '',
  }));
  const defaultDepositOptions = [10, 20, 30, 40, 50];

  // Flags
  const [isPrecalculated, setIsPrecalculated] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  // Object or Array states
  const [availableDepositOptions, setAvailableDepositOption] = useState<number[]>(defaultDepositOptions);

  // Single value states
  const [financeTerm, setFinanceTerm] = useState<FinanceTermOption>(financeOptions[0]);
  const [financeDeposit, setFinanceDeposit] = useState<number>(defaultDepositOptions[1]);
  const [financeSummary, setFinanceSummary] = useState<FinanceCalculation>();

  const [amountValue, setAmountValue] = useState<string>(parseFloat(amountToString).toFixed(2));
  const [precalculatedDepositOptions, setPrecalculatedDepositOptions] = useState<FinanceDepositOptions>();

  function handleDepositChange(e: ChangeEvent<HTMLSelectElement>) {
    setFinanceDeposit(parseInt(e.target.value));
  }

  function handleTermOptionChange(financeTerm: FinanceTermOption) {
    setFinanceTerm(financeTerm);
    if (termCallback) termCallback(financeTerm);
  }

  function renderFinanceSummary() {
    if (!financeSummary) return;

    let financeSummaryAmount = '0.00';
    let financeSummaryDeposit = '0.00';
    let financeSummaryLoanAmount = '0.00';
    let financeSummaryAnnualRate = '0.00';
    let financeSummaryInitialPayment = '0.00';
    let financeSummaryChargeForCredit = '0.00';
    let financeSummaryAmountPayable = '0.00';

    if (!isDisabled) {
      financeSummaryAmount = amountValue;
      financeSummaryDeposit = financeSummary.deposit;
      financeSummaryLoanAmount = financeSummary.loanAmount;
      financeSummaryAnnualRate = financeSummary.annualRate;
      financeSummaryInitialPayment = financeSummary.initialPayments;
      financeSummaryChargeForCredit = financeSummary.chargeForCredit;
      financeSummaryAmountPayable = financeSummary.amountPayable;
    }

    return (
      <FinanceSummary
        productAmount={`${currencySymbol}${financeSummaryAmount}`}
        depositAmount={`${currencySymbol}${financeSummaryDeposit}`}
        totalCreditAmount={`${currencySymbol}${financeSummaryLoanAmount}`}
        annualInterestRate={`${financeSummaryAnnualRate}%`}
        monthlyAmount={`${currencySymbol}${financeSummaryInitialPayment}`}
        totalCreditCost={`${currencySymbol}${financeSummaryChargeForCredit}`}
        totalAmount={`${currencySymbol}${financeSummaryAmountPayable}`}
      />
    );
  }

  useEffect(() => {
    const precalculatedDeposits: FinanceDepositOptions = {};
    financeProducts.forEach((financeProduct) => {
      const result = defaultDepositOptions.map((depositOption) => {
        const depositAmount = calculateDeposit(parseFloat(amountValue), depositOption);
        const calculatedResult = calculate(financeProduct, parseFloat(amountValue), depositAmount);

        return {
          depositPercentage: depositOption,
          financeProduct: financeProduct,
          financeCalculation: calculatedResult,
        } as FinanceDepositPrecalculation;
      });

      precalculatedDeposits[financeProduct.tag] = result;
    });

    setPrecalculatedDepositOptions(precalculatedDeposits);
    setIsPrecalculated(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountValue, financeProducts]);

  useEffect(() => {
    const financePlanSelected =
      financeProducts.find((p) => p.productId === financeTerm.productId) ?? financeProducts[0];
    const financeDepositAvailable =
      financePlanSelected && precalculatedDepositOptions ? precalculatedDepositOptions[financePlanSelected.tag] : null;

    if (financeDepositAvailable) {
      const percentOptions = financeDepositAvailable
        .filter((o) => parseFloat(o.financeCalculation.loanAmount) >= o.financeProduct.minLoan)
        .map((o) => o.depositPercentage);

      if (percentOptions.length == 1) {
        setFinanceDeposit(percentOptions[0]);
      }

      setAvailableDepositOption(percentOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountValue, precalculatedDepositOptions, financeTerm, isPrecalculated]);

  useEffect(() => {
    const financePlanSelected =
      financeProducts.find((p) => p.productId === financeTerm.productId) ?? financeProducts[0];
    const financeDepositAvailable =
      financePlanSelected && precalculatedDepositOptions ? precalculatedDepositOptions[financePlanSelected.tag] : null;

    const finance =
      financeDepositAvailable && financeDepositAvailable.find((f) => f.depositPercentage === financeDeposit);

    if (finance) {
      setFinanceSummary(finance.financeCalculation);
      if (depositCallback) depositCallback(finance.financeCalculation.deposit);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountValue, financeDeposit, availableDepositOptions]);

  useEffect(() => {
    if (parseFloat(amountValue) > MIN_FINANCE_AMOUNT) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [amountValue]);

  useEffect(() => {
    if (termCallback) termCallback(financeOptions[0]);
    if (depositCallback) depositCallback(financeSummary?.deposit || '0');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return financeProducts.length > 0 ? (
    <>
      {configurableAmount && (
        <FinanceAmountInput
          currencySymbol={currencySymbol}
          amountValue={amountValue}
          setAmountValue={setAmountValue}
          minimumAmount={MIN_FINANCE_AMOUNT}
          isReadOnly={!configurableAmount}
        />
      )}

      <div className={clsx('mb-6 mt-5 border-b border-b-grey-3 pb-7', { 'opacity-50': isDisabled })}>
        <FinanceTermOptions
          disabled={isDisabled}
          financeOptions={financeOptions}
          activeOption={financeTerm}
          onChange={handleTermOptionChange}
        />
      </div>

      <div className={clsx('mb-4.5', { 'opacity-50': isDisabled })}>
        <FinanceDeposit
          disabled={isDisabled}
          activeDepositOption={financeDeposit}
          depositOptions={availableDepositOptions}
          onChange={handleDepositChange}
        />
      </div>

      <div className={clsx('mt-4.5', { 'opacity-50': isDisabled })}>{renderFinanceSummary()}</div>
    </>
  ) : (
    <></>
  );
}
