/* eslint-disable @typescript-eslint/no-unused-vars */
import { addMonths, differenceInDays, formatISO, isBefore } from 'date-fns';

interface FinanceProduct {
  productId: string;
  tag: string;
  altTag?: string;
  apr: number;
  monthlyRate: number;
  months: number;
  serviceFee: number;
  deferredPeriod: number;
  documentFee: number;
  documentFeePercentage: number;
  documentFeeMinimum: number;
  documentFeeMaximum: number;
  documentFeeCollectionMonth: number;
  minLoan: number;
  maxLoan: number;
  productGuid: string;
  name: string;
  settlementFee: number;
}

export interface FinanceCalculation {
  initialPayments: string;
  finalPayment: string;
  balancePayable: string;
  interest: string;
  chargeForCredit: string;
  amountPayable: string;
  cashPrice: string;
  deposit: string;
  loanAmount: string;
  months: number;
  monthsDeferred: number;
  apr: string;
  productAvailable: boolean;
  availabilityReason: string;
  productId: string;
  productGuid: string;
  name: string;
  settlementFee: string;
  serviceFee: string;
  documentFee: string;
  documentFeeMinimum: number;
  documentFeeMaximum: number;
  documentFeeCollectionMonth: number;
  documentFeePercentage: number;
  annualRate: string;
}

interface CashFlow {
  cashFlows: number;
  dataDate: string;
}
function calculateApr(loan: number, instalment: number, deferred: number, term: number): number {
  let result = 0;
  let high = 200;
  let low = 0;
  let x = 1;
  const n = deferred > 1 ? term + deferred + 1 : term + 1;

  while (x < 20) {
    result = (high + low) / 2;
    const j = Math.pow(1.0 + result / 100.0, 1.0 / 12.0);
    const q = 1.0 / j;

    let y: number, z: number;
    if (deferred < 1) {
      y = (instalment * (1.0 - Math.pow(q, n))) / (1 - q) - instalment;
      z = 0.0;
    } else {
      y = (instalment * (1.0 - Math.pow(q, n - 1))) / (1 - q) - instalment;
      z = (instalment * (1.0 - Math.pow(q, deferred))) / (1 - q) - instalment;
    }

    if (y - z < loan) {
      high = result;
    } else {
      low = result;
    }

    x++;
  }

  return result;
}

function sumCashFlows(months: number, cashflows: CashFlow[]): number {
  let total = 0.0;

  for (let i = 1; i < months; i++) {
    total += cashflows[i - 1].cashFlows;
  }

  return total;
}

function earliestDate(cashflows: CashFlow[], months: number): string {
  let earliest = cashflows[0].dataDate;

  for (let i = 1; i < months; i++) {
    if (isBefore(new Date(cashflows[i].dataDate), new Date(earliest))) {
      earliest = cashflows[i].dataDate;
    }
  }

  return earliest;
}

function presentValue(
  cashflows: CashFlow[],
  irr: number,
  loanTerm: number,
  checkdate: string,
  numdays: number,
): number {
  let presValue = 0.0;

  for (let i = 0; i < loanTerm; i++) {
    const cf = cashflows[i].cashFlows;
    const diff = differenceInDays(new Date(checkdate), new Date(cashflows[i].dataDate));
    presValue += cf / Math.pow(1 + irr, diff / numdays);
  }

  return presValue;
}

function XIRR(values: number[], dates: string[], guess: number): number | string {
  const irrResult = (values: number[], dates: string[], rate: number): number => {
    const r = rate + 1;
    let result = values[0];
    for (let i = 1; i < values.length; i++) {
      result += values[i] / Math.pow(r, differenceInDays(new Date(dates[i]), new Date(dates[0])) / 365);
    }
    return result;
  };

  const irrResultDeriv = (values: number[], dates: string[], rate: number): number => {
    const r = rate + 1;
    let result = 0;
    for (let i = 1; i < values.length; i++) {
      const frac = differenceInDays(new Date(dates[i]), new Date(dates[0])) / 365;
      result -= (frac * values[i]) / Math.pow(r, frac + 1);
    }
    return result;
  };

  let positive = false;
  let negative = false;
  for (let i = 0; i < values.length; i++) {
    if (values[i] > 0) positive = true;
    if (values[i] < 0) negative = true;
  }

  if (!positive || !negative) return '#NUM!';

  let resultRate = guess || 0.1;
  const epsMax = 1e-10;
  const iterMax = 50;
  let iteration = 0;
  let contLoop = true;

  do {
    const resultValue = irrResult(values, dates, resultRate);
    const newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
    const epsRate = Math.abs(newRate - resultRate);
    resultRate = newRate;
    contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax;
  } while (contLoop && ++iteration < iterMax);

  return contLoop ? '#NUM!' : resultRate;
}

function calculateAprFromIrr(
  loan: number,
  monthlyinstalment: number,
  loanTerm: number,
  documentfee: number,
  documentfeecollectionmonth: number,
): number {
  const startDate = new Date();
  const incomeTable: number[] = [];
  const dateTable: Date[] = [];

  if (documentfeecollectionmonth === 0) {
    incomeTable.push(loan * -1 + documentfee);
  } else {
    incomeTable.push(loan * -1);
  }
  dateTable.push(startDate);

  for (let i = 1; i <= loanTerm; i++) {
    const nextDate = addMonths(startDate, i);
    dateTable.push(nextDate);

    if (i - 1 === documentfeecollectionmonth && documentfeecollectionmonth > 0) {
      incomeTable.push(monthlyinstalment + documentfee);
    } else {
      incomeTable.push(monthlyinstalment);
    }
  }
  const r = XIRR(
    incomeTable,
    dateTable.map((date) => formatISO(date)),
    0.1,
  );

  return typeof r === 'number' ? Math.round(r * 10000) / 100 : parseInt(r);
}

export function calculateDeposit(cashPrice: number, depositFactor: number) {
  let deposit = (depositFactor / 100) * cashPrice;
  if (depositFactor <= 10) {
    deposit = parseFloat(Math.ceil(deposit).toString()).toFixed(2) as unknown as number;
  } else if (depositFactor >= 50) {
    deposit = parseFloat(Math.floor(deposit).toString()).toFixed(2) as unknown as number;
  } else {
    deposit = parseFloat(Math.round(deposit).toString()).toFixed(2) as unknown as number;
  }
  return deposit;
}

export function calculate(financeProduct: FinanceProduct, cashPrice: number, deposit: number): FinanceCalculation {
  const apr = financeProduct.apr;
  const monthlyrate = financeProduct.monthlyRate;
  const months = financeProduct.months;
  const serviceFee = financeProduct.serviceFee;
  const deferredPeriod = financeProduct.deferredPeriod;
  deposit = parseFloat(deposit.toString());

  let balancePayable = 0;
  let documentFee = 0;

  const loanAmount = cashPrice - deposit;
  balancePayable = loanAmount;

  documentFee = financeProduct.documentFee + loanAmount * financeProduct.documentFeePercentage;
  if (financeProduct.documentFeeMinimum > 0 && documentFee < financeProduct.documentFeeMinimum) {
    documentFee = financeProduct.documentFeeMinimum;
  }
  if (financeProduct.documentFeeMaximum > 0 && documentFee > financeProduct.documentFeeMaximum) {
    documentFee = financeProduct.documentFeeMaximum;
  }

  let initialPayments: number;
  let finalPayment: number;
  let calculatedApr: number;

  if (monthlyrate === 0) {
    initialPayments = Math.round((loanAmount / months) * 100) / 100;
    if (initialPayments * months < loanAmount) {
      initialPayments += 0.01;
    }
    finalPayment = loanAmount - initialPayments * (months - 1);
    calculatedApr = 0;
  } else {
    const financeYield = Math.pow(apr / 100 + 1, 1.0 / 12);
    let pv = loanAmount - serviceFee;
    if (deferredPeriod > 1) {
      pv *= Math.pow(financeYield, deferredPeriod - 1);
    }
    initialPayments = Math.ceil((0 - pv / ((Math.pow(financeYield, 0 - months) - 1) / (financeYield - 1))) * 100) / 100;
    finalPayment = initialPayments;
    balancePayable = initialPayments * months;
    calculatedApr = calculateApr(loanAmount - serviceFee, initialPayments, deferredPeriod, months);
  }

  if (documentFee > 0) {
    calculatedApr = calculateAprFromIrr(
      loanAmount,
      initialPayments,
      months,
      documentFee,
      financeProduct.documentFeeCollectionMonth,
    );
  }

  const interest = balancePayable - loanAmount;
  const chargeForCredit = interest + serviceFee + documentFee;
  const amountPayable = balancePayable + serviceFee + documentFee + deposit;

  let productAvailable = true;
  let availabilityReason = '';

  if (loanAmount < financeProduct.minLoan) {
    productAvailable = false;
    availabilityReason = `Only available on loan amounts over £${financeProduct.minLoan.toFixed(2)}`;
  } else if (loanAmount > financeProduct.maxLoan) {
    productAvailable = false;
    availabilityReason = `Only available on loan amounts under £${financeProduct.maxLoan.toFixed(2)}`;
  }

  const x = (interest / loanAmount) * 100;
  const y = (months + financeProduct.deferredPeriod) / 12;
  const annualRate = x / y;

  return {
    initialPayments: initialPayments.toFixed(2),
    finalPayment: finalPayment.toFixed(2),
    balancePayable: balancePayable.toFixed(2),
    interest: interest.toFixed(2),
    chargeForCredit: chargeForCredit.toFixed(2),
    amountPayable: amountPayable.toFixed(2),
    cashPrice: cashPrice.toFixed(2),
    deposit: deposit.toFixed(2),
    loanAmount: loanAmount.toFixed(2),
    months: months,
    monthsDeferred: deferredPeriod,
    apr: calculatedApr.toFixed(2),
    productAvailable: productAvailable,
    availabilityReason: availabilityReason,
    productId: financeProduct.productId,
    productGuid: financeProduct.productGuid,
    name: financeProduct.name,
    settlementFee: financeProduct.settlementFee.toFixed(2),
    serviceFee: serviceFee.toFixed(2),
    documentFee: documentFee.toFixed(2),
    documentFeeMinimum: financeProduct.documentFeeMinimum,
    documentFeeMaximum: financeProduct.documentFeeMaximum,
    documentFeeCollectionMonth: financeProduct.documentFeeCollectionMonth,
    documentFeePercentage: financeProduct.documentFeePercentage,
    annualRate: annualRate.toFixed(2),
  };
}
