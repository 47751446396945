import { useId, SelectHTMLAttributes } from 'react';
import clsx from 'clsx';
import { MdKeyboardArrowDown } from 'react-icons/md';

interface FNCSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
}

export default function FNCSelect({ className, children, label, ...props }: FNCSelectProps): JSX.Element {
  const id = useId();

  return (
    <div className={clsx('relative flex h-[58px] flex-col gap-y-1', className)}>
      <label className="absolute left-3 top-2 text-12 text-grey-7" htmlFor={id} data-testid="label">
        {label}
      </label>

      <select
        className="surface-white disabled:surface-grey-2 h-full w-full appearance-none rounded-md border border-solid border-grey-7 pb-1 pl-3 pr-12 pt-4 text-16 leading-5"
        data-testid="field"
        id={id}
        {...props}
      >
        {children}
      </select>

      <MdKeyboardArrowDown className="pointer-events-none absolute right-3 top-1/2 h-[24px] w-[24px] translate-y-[-50%] text-[8px] text-grey-5" />
    </div>
  );
}
