import { ChangeEventHandler } from 'react';
import { useFormat } from '~/helpers/hooks/useFormat';
import FNCSelect from '../../fnc/fnc-select';

export interface FinanceDepositOption {
  option: number;
  minAmount: number;
  productAmountAfterDeposit: number;
}

interface FinanceDepositProp {
  disabled?: boolean;
  activeDepositOption: number;
  depositOptions: number[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
}

export default function FinanceDeposit({
  disabled,
  activeDepositOption,
  depositOptions,
  onChange,
}: FinanceDepositProp): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <>
      <span className="my-2 block text-16 font-bold leading-4.5">
        {formatMessage({ id: 'components.finance-calculator.deposit-amount-title' })}
      </span>
      <p className="mb-4.5 mt-2 block text-14 leading-4">
        {formatMessage({ id: 'components.finance-calculator.deposit-amount-sub' })}
      </p>
      <FNCSelect
        label="Deposit amount"
        className="!h-[48px] [&>label]:hidden [&>select]:border-grey-3 [&>select]:pt-1 [&>select]:font-bold"
        onChange={onChange}
        value={activeDepositOption}
        disabled={disabled}
      >
        {depositOptions.map((o, k) => (
          <option key={k} value={o}>
            {o}%
          </option>
        ))}
      </FNCSelect>
    </>
  );
}
