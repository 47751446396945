import clsx from 'clsx';
import { useFormat } from '~/helpers/hooks/useFormat';

interface FinanceSummaryProp {
  productAmount: string;
  depositAmount: string;
  totalCreditAmount: string;
  annualInterestRate: string;
  monthlyAmount: string;
  totalCreditCost: string;
  totalAmount: string;
}

export default function FinanceSummary({
  productAmount,
  depositAmount,
  totalCreditAmount,
  annualInterestRate,
  monthlyAmount,
  totalCreditCost,
  totalAmount,
}: FinanceSummaryProp): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div
      className={clsx(
        'rounded-md border border-grey-3 bg-grey-1 px-4 [&>div:last-child]:border-none [&>div]:py-3',
        '[&>div]:flex [&>div]:items-center [&>div]:justify-between [&>div]:border-b [&>div]:border-b-grey-3 [&>div]:text-14 [&>div]:leading-4',
      )}
    >
      <div>
        <span>{formatMessage({ id: 'components.finance-calculator.summary.product-price' })}</span>
        <span className="font-bold">{productAmount}</span>
      </div>

      <div>
        <span>{formatMessage({ id: 'components.finance-calculator.summary.deposit' })}</span>
        <span className="font-bold">{depositAmount}</span>
      </div>

      <div>
        <span>{formatMessage({ id: 'components.finance-calculator.summary.total-credit' })}</span>
        <span className="font-bold">{totalCreditAmount}</span>
      </div>

      <div>
        <span>{formatMessage({ id: 'components.finance-calculator.summary.annual-interest' })}</span>
        <span className="font-bold">{annualInterestRate}</span>
      </div>

      <div>
        <span>{formatMessage({ id: 'components.finance-calculator.summary.monthly-amount' })}</span>
        <span className="font-bold">{monthlyAmount}</span>
      </div>

      <div>
        <span>{formatMessage({ id: 'components.finance-calculator.summary.total-credit-cost' })}</span>
        <span className="font-bold">{totalCreditCost}</span>
      </div>

      <div>
        <span>{formatMessage({ id: 'components.finance-calculator.summary.total-cost' })}</span>
        <span className="font-bold">
          <span className="font-bold">{totalAmount}</span>
        </span>
      </div>
    </div>
  );
}
