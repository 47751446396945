import { useId, InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import { MdCheck } from 'react-icons/md';

interface FNCRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  supportingText?: string;
  name: string;
}

export default function FNCRadio({ className, label, supportingText, name, ...props }: FNCRadioProps): JSX.Element {
  const id = useId();

  return (
    <div className={className}>
      <div className={clsx('flex gap-x-2', { 'items-center': !supportingText })} data-testid="inner-container">
        <div className="relative flex">
          <input
            className="peer relative h-[22px] w-[22px] appearance-none rounded-sm border border-solid border-grey-9 bg-white"
            data-testid="input"
            id={id}
            type="radio"
            name={name}
            {...props}
          />
          <MdCheck className="surface-green-2 fade-in-initial peer-checked:fade-in-enter pointer-events-none absolute z-10 h-[22px] w-[22px] rounded-sm fill-white text-22" />
        </div>

        <div className="flex flex-col gap-y-1">
          <label
            className={clsx('text-grey-5', { 'text-14': !supportingText, 'text-18': supportingText })}
            htmlFor={id}
            data-testid="label"
          >
            {label}
          </label>

          {supportingText && (
            <p className="text-14 leading-[18px] text-grey-4 lg:leading-5" data-testid="supporting-text">
              {supportingText}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
