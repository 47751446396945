import { useFormat } from '~/helpers/hooks/useFormat';
import FNCRadio from '../../fnc/fnc-radio';

export type FinanceTermOption = {
  productId: string;
  term: number;
  interest: number;
  label: string;
};

interface FinanceTermOptionsProps {
  disabled?: boolean;
  activeOption: FinanceTermOption;
  financeOptions: FinanceTermOption[];
  onChange: (selectedTerm: FinanceTermOption) => void;
}

export default function FinanceTermOptions({
  disabled = false,
  activeOption,
  financeOptions,
  onChange,
}: FinanceTermOptionsProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const financeOptionsCheckbox = financeOptions.map((o, k) => (
    <FNCRadio
      key={k}
      label={o.label}
      className="my-2 text-14 leading-4"
      onChange={() => onChange(o)}
      checked={o.productId === activeOption.productId}
      name="financeOption"
      data-testid={`termOption${o.term}`}
      disabled={disabled}
    />
  ));

  return (
    <>
      <span className="text-18 font-bold leading-5">
        {formatMessage({ id: 'components.finance-calculator.finance-options-title' })}
      </span>
      <div>{financeOptionsCheckbox}</div>
    </>
  );
}
